<template>
  <div v-if="!!chat && !!user && chat.participants?.length >= 2" class="
      w-full
      py-3
      px-4
      flex
      justify-between
      items-center
      rounded-md
      cursor-pointer hover:shadow-md transition duration-300 ease-out
      relative
    " :class="{
      'bg-orange-tint border-orange': unread > 0,
      'border-orange-tint': unread == 0,
    }" @click="
  () => {
    unread = 0;
    this.$router.push(
      `/chats/${chat.participants[0]._id}:${chat.participants[1]._id}:`
    );
  }
">
    <div class="absolute mt-1 mr-2 right-0 top-0 font-extralight" style="font-size: 9px !important">
      {{ time }}
    </div>
    <img :src="user.img_url" alt="" class="w-10 h-10 flex-shrink-0 rounded-full" />
    <div class="mx-3 truncate w-full">
      <p class=" text-body font-bold truncate capitalize  -mb-1" :class="{ 'text-orange': unread > 0 }">
        {{ user.name }}
      </p>
      <p class="font-poppins text-gray-500 font-normal truncate text-xs" :class="{ 'text-orange': unread > 0 }"
        v-if="!!chat && !!chat.last_message">
        {{ chat.last_message.text }}
      </p>
    </div>
    <div :class="{ 'w-12': unread > 0, 'w-6': unread == 0 }">
      <div v-if="unread" class="
          w-5
          h-5
          bg-orange
          rounded-full
          mx-auto
          flex
          items-center
          justify-center
        ">
        <p class="font-bold text-white text-sm">{{ unread }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserById } from "../helpers/getters";
import { relative_time } from "../helpers/parser";

export default {
  props: {
    chat: Object,
  },
  data() {
    return {
      time: !!this.chat && !!this.chat.last_message ? relative_time(this.chat.last_message.timestamp) : '',
      unread: this.chat.participants[0]._id == this.$store.state.user._id
        ? this.chat.participants[0].unread
        : this.chat.participants[1].unread,
      user: null,
    };
  },
  computed: {
    recipient() {
      return this.chat.participants[0]._id == this.$store.state.user._id
        ? this.chat.participants[1]
        : this.chat.participants[0];
    },
  },
  created() {
    getUserById(this.recipient._id).then(({ data }) => {
      this.user = data;
    });
  }
};
</script>
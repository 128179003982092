<template>
  <page-layout :linkDefault="`/`" title="Chats" :backButton="true">
    <Loading v-if="loading" />
    <div v-else>
    <p class="font-poppins font-medium text-base leading-6 mx-6">Messages</p>
    <ul class="relative" v-if="chats && chats.length === user.chats.length">
        <li v-for="chat, index in chats" :key="index">
          <chat-preview :chat="chat"/>
        </li>
    </ul>
  </div>
  </page-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageLayout from "../components/base/pageLayout.vue";
import ChatPreview from '../components/chatPreview.vue';
import Loading from "../components/Loading.vue"
export default {
  computed: {
    ...mapState(['chats', 'chat_previewed', 'username', 'user']),
  },
  components: {
    PageLayout,
    ChatPreview,
    Loading
  },
  data() {
    return {
      loading:true,
    };
  },
  methods: {
    ...mapActions(['get_chats']),
    sortChats() {
      if(!this.chats) return
      return this.chats.sort((a,b) => (!!a.last_message && !!b.last_message && a.last_message.timestamp < b.last_message.timestamp) ? 1 : ((!!a.last_message && !!b.last_message && b.last_message.timestamp < a.last_message.timestamp) ? -1 : 0));
    }
  },
  async ionViewWillEnter() {
    await this.get_chats();
    this.loading=false;
  },
  watch: {
    chats: {
      handler: function () {
       this.sortChats()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>